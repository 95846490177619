import Stack from '@mui/material/Stack'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import * as React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Link from 'next/link'
import EastIcon from '@mui/icons-material/East'

interface IProps {
  path: string
  children: React.ReactNode
  handleClick(): void
  color?: string
  items?: SubMenu[] | undefined
}

const NavItem: React.FC<IProps> = ({
  path,
  children,
  color = 'text.primary',
  items,
  handleClick,
}) => {
  const { asPath, push } = useRouter()
  const [open, setOpen] = React.useState(false)

  const handleClickSub = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Stack sx={{ position: 'relative' }}>
        <Stack
          direction="row"
          alignItems="center"
          onClick={() => (items?.length ? handleClickSub() : push(path))}
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            component="a"
            variant="h6"
            fontSize={16}
            onClick={!items?.length ? handleClick : undefined}
            color={color}
            sx={{
              '&:hover': {
                color: 'secondary.main',
                transition: 'all .5s',
                transform: 'scale(1.05)',
              },
              ...(asPath === path && {
                position: 'relative',
                '&:after': {
                  content: '""',
                  width: '80%',
                  height: 10,
                  bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.5),
                  position: 'absolute',
                  bottom: '5px',
                  zIndex: '-1',
                  left: '-4px',
                  borderRadius: 1,
                },
              }),
            }}
          >
            {children}
          </Typography>
          {items?.length ? <KeyboardArrowDownIcon /> : false}
        </Stack>
        {open && (
          <Stack
            onClick={handleClickSub}
            sx={{
              width: 200,
              position: 'absolute',
              bgcolor: 'background.paper',
              left: 0,
              top: 70,
              p: 2,
              zIndex: 9,
              borderRadius: 1,
              boxShadow: '0 5px 10px 0 rgba(0,0,0,0.2)',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: '50%',
                width: 15,
                height: 15,
                boxShadow: '-5px 0px 5px 0 rgb(0 0 0 / 10%)',
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            }}
          >
            {items?.map((item, i) => (
              <Link key={i} href={`${item.path}`} passHref>
                <Stack direction="row" spacing={1} sx={{ my: 1 }}>
                  <EastIcon />
                  <Typography
                    fontSize={16}
                    sx={{
                      color:
                        `/${item?.path}` === asPath
                          ? 'secondary.main'
                          : 'text.secondary',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'secondary.main',
                        transition: 'all .5s',
                      },
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  )
}

export default NavItem
