import axios from 'axios'

export function getStrapiURL(path = '') {
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'
  }/api${path}`
}

export function getStrapiImage(image: Media) {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL || 'http://localhost:1337'}${
    image?.data?.attributes?.url
  }`
}

export function getStrapiImagePlaceholder(image: Media) {
  return image?.data?.attributes?.placeholder
}

export function getStrapiImageWidth(image: Media) {
  return image?.data?.attributes?.width
}

export function getStrapiImageHeight(image: Media) {
  return image?.data?.attributes?.height
}

export async function getData(path: string) {
  try {
    const requestUrl = getStrapiURL(path)
    const response = await axios.get(requestUrl)
    return response.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      return error.message
    } else {
      throw new Error(`Error! Failed to fetch data on ${path} route`)
    }
  }
}
