import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

const PopUp = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose(): void
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        alignItems="center"
        justifyContent="center"
        sx={{ py: 1 }}
      >
        Coming soon!
        <IconButton sx={{ float: 'right' }} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {`Les inscriptions seront ouvertes le 11 Juillet 2022. Veuillez consulter le programme pour plus d'informations.`}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default PopUp
