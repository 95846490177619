import { createTheme } from '@mui/material/styles'

export const theme = (mode: 'light' | 'dark') =>
  createTheme({
    palette: {
      mode: mode,
      background: {
        default: mode === 'light' ? '#fafafa' : '#212121',
        paper: '#fafafa',
      },
      primary: {
        main: '#121212',
      },
      secondary: {
        main: '#eba120',
        light: 'rgb(235 161 32 / 15%);',
      },
      accent: {
        main: '#ececec',
      },
      white: {
        main: '#fff',
      },
    },
    typography: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      h3: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
      },
      h4: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
      },
      h5: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
      },
      h6: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
      },
    },
  })

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary']
    white: Palette['primary']
  }
  interface PaletteOptions {
    accent?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true
    white: true
  }
}
