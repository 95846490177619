import { useLockedBody } from '@/hooks/useLockedBody'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Container, Stack, Toolbar } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { getStrapiImage } from '../../lib/api'
import PopUp from '../popUp/PopUp'
import { NavItem } from './components'

interface IProps {
  threshold?: number
  subMenu: SubMenu[]
  logo: Media
  soumettre: Cta
}

const NavBar: React.FC<IProps> = ({
  threshold = 740,
  subMenu,
  logo,
  soumettre,
}) => {
  const navItems = [
    {
      label: 'Accueil',
      path: '/',
    },
    {
      label: 'Éditions passées',
      path: '/#editions',
      items: subMenu,
    },
  ]

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold })
  const { push } = useRouter()
  const [locked, setLocked] = useLockedBody()

  const onShowMenu = () => {
    setLocked(!locked)
  }

  return (
    <AppBar
      position={trigger ? 'fixed' : 'absolute'}
      color={trigger ? 'default' : 'transparent'}
      sx={{ bgcolor: trigger ? 'default' : 'transparent' }}
      {...(!locked && trigger
        ? { variant: 'outlined' }
        : { variant: 'elevation', elevation: 0 })}
    >
      <PopUp open={open} handleClose={handleClose} />
      <Toolbar>
        <Container>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', md: 'center' }}
            sx={{ py: !locked && trigger ? 1 : 2 }}
          >
            <Stack direction="row">
              <IconButton sx={{ display: { md: 'none' } }} onClick={onShowMenu}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ cursor: 'pointer' }} onClick={() => push('/')}>
                <Stack
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    height: {
                      xs: !locked && trigger ? 50 : 60,
                      md: !locked && trigger ? 50 : 70,
                    },
                    width: 120,
                    position: 'relative',
                  }}
                >
                  <Image
                    src={getStrapiImage(logo)}
                    // blurDataURL={getStrapiImagePlaceholder(logo)}
                    // placeholder="blur"
                    alt="updev challenge logo"
                    layout="fill"
                    objectFit="contain"
                    objectPosition={0}
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack
              alignItems="center"
              direction={{ xs: 'column', md: 'row' }}
              spacing={4}
              sx={{
                display: { xs: 'none', md: 'flex' },
                ...(locked && {
                  position: 'absolute',
                  display: 'flex',
                  top: 0,
                  bgcolor: 'background.default',
                  width: 1,
                  margin: 0,
                  left: 0,
                  height: '100vh',
                  bottom: 0,
                  right: 0,
                  overflow: 'hidden',
                  // pl: 5,
                  pt: 16,
                }),
              }}
            >
              <IconButton
                sx={{
                  display: { md: 'none' },
                  position: 'absolute',
                  left: 25,
                  top: 25,
                }}
                onClick={onShowMenu}
              >
                <CloseIcon />
              </IconButton>

              {navItems?.map((el) => (
                <NavItem
                  path={el?.path}
                  key={el.label}
                  items={el.items}
                  handleClick={() => setLocked(false)}
                >
                  {el?.label}
                </NavItem>
              ))}

              <Typography
                component="a"
                variant="h6"
                fontSize={16}
                target="_blank"
                rel="noreferrer"
                href="https://uptodatedevelopers.com/gallery/1/updev-challenge"
              >
                Galerie
              </Typography>

              <Stack
                sx={{
                  width: 2,
                  height: 45,
                  bgcolor: 'divider',
                  display: { xs: 'none', md: 'flex' },
                }}
              ></Stack>

              <NavItem
                path={'/#contact'}
                handleClick={() => setLocked(false)}
                color="secondary.main"
              >
                Contactez-nous
              </NavItem>

              <Button
                variant="contained"
                sx={{ px: 4, py: 1, borderRadius: 2 }}
                disableElevation
                {...(soumettre?.lien
                  ? {
                      component: 'a',
                      href: soumettre?.lien,
                      target: '_blank',
                      rel: 'noreferrer',
                    }
                  : {
                      onClick: handleOpen,
                    })}
              >
                {soumettre?.texte}
              </Button>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
