import { theme } from '@/utils/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import type { NextPage } from 'next'
import * as React from 'react'

interface IProps {
  children: React.ReactElement[] | React.ReactElement
}

const Wrapper: NextPage<IProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme('light')}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default Wrapper
